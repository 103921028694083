.po_items_ho {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .po_item {
    flex: 0 0 calc(25% - 1rem);
    text-align: center;
    position: relative;
    background: var(--secondary-color);
    padding: 6px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
    min-height: 300px;
    margin-bottom: 1rem;
  }
  
  .po_item img {
    max-width: 100%;
    height: auto;
  }
  
  .po_item .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    border: 1px solid black;
  }
  
  .po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
  }
  
  .po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
  }
  
  .po_item .content {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
  }
  
  .po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
  }
  
  .po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
  }
  
  .po_item .content a:hover {
    text-decoration: none;
  }
  